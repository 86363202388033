export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static maxDocFileSize: number = 5242880; // 5MB
    static maxImgFileSize: number = 2097152; // 2MB
    static maxAssetFileSize: number = 10485760; // 10MB
    static maxImgDimension: number = 4000; // 2MB

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'InventoryManagement'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly PhysicalItemStatuses = {
        Good: 0,
        Acceptable: 1,
        Bad: 2,
        Unspecified: 3,
        VeryGood: 4
    }

    static readonly PhysicalItemCategories = {
        FixedAssets: 0,
        InventoryItems: 1,
        ReplacementPieces: 2
    }

    static readonly InventoryTypes = {
        Identification: 0,
        Scan: 1,
    }
   
    static readonly ActionTypes = {
        Inventory: 0,
        Revision: 1,
        Repair: 2,
    }

    static readonly MaintenanceFrequencyTypes = {
        None: 0,
        Day: 1,
        Week: 2,
        Month: 3
    }

    static readonly printAssetCookieFields = 'printAssetCookieFields';

    static readonly lastPrinterName = 'lastPrinterName';

    static readonly AssetImportFields = {
        Name: 'name',
        Number: 'number',
        IDSN: 'idsn',
        Description: 'description',
        CostCenter: 'costCenter',
        Status: 'status',
        Category: 'category',
        RequireMaintenance: 'requireMaintenance',
        MaintenanceFrequencyType: 'maintenanceFrequencyType',
        NextRevision: 'nextRevision',
        MaintenanceInterval: 'maintenanceInterval',
        RevisionAgentEmail: 'revisionAgentEmail',
        RevisionInstructions: 'revisionInstructions',
        PoiName: 'poiName',
        PhysicalLocationDetails: 'physicalLocationDetails',
        PoiLatitude: 'poiLatitude',
        PoiLongitude: 'poiLongitude',
        MarkedForCasation: 'markedForCasation',
        CurrentIndex: 'currentIndex',
        IndexReadingDate: 'indexReadingDate',
        DueIndex: 'dueIndex',
        MaintenanceIndex: 'maintenanceIndex',
        Beneficiary: 'beneficiary',
        BeneficiaryMark: 'beneficiaryMark',
        AquisitionDetails: 'aquisitionDetails',
        AquisitionDate: 'aquisitionDate',
        AquisitionValue: 'aquisitionValue',
        AquisitionRemainingValue: 'aquisitionRemainingValue',
        LinearAmortization: 'linearAmortization',
        AmortizationMonthsStandard: 'amortizationMonthsStandard',
        AmortizationMonthsRemaining: 'amortizationMonthsRemaining',
        Warranty: 'warranty',
        WarrantyPeriod: 'warrantyPeriod',
        WarrantyExpirationDate: 'warrantyExpirationDate',
    }

    static readonly CostCenterImportFields = {
        Name: 'name',
        Description: 'description'
    }

    static readonly PhysicalLocationFields = {
        Name: 'name',
        Latitude: 'latitude',
        Longitude: 'longitude'
    }

    static readonly BeneficiaryFields = {
        Name: 'name',
        Mark: 'mark',
        JobPosition: 'jobPosition',
        Department: 'department',
        Address: 'address',
        IdCardSerial: 'idCardSerial',
        IdCardNumber: 'idCardNumber',
        IdCardProvider: 'idCardProvider',
        IdCardProvidedDate: 'idCardProvidedDate',
        SSN: 'ssn',
        ContractNumber: 'contractNumber',
        ContractDate: 'contractDate'
    }

    static readonly SettingNames = {
        InventoryWithCoordinates: "InventoryWithCoordinates",
        LocationRadius: "LocationRadius",
        SendNextMaintenancePlanning: "SendNextMaintenancePlanning",
        SendOutdatedMaintenancePlanning: "SendOutdatedMaintenancePlanning",
        SendMaintenancePlanningToAdmins: "SendMaintenancePlanningToAdmins",
        SendNextRepairPlanning: "SendNextRepairPlanning",
        SendOutdatedRepairPlanning: "SendOutdatedRepairPlanning",
        SendRepairPlanningToAdmins: "SendRepairPlanningToAdmins",
        AddMaintenanceIndex: "AddMaintenanceIndex",
        MaintenanceMaxDuration: "MaintenanceMaxDuration",
        RepairMaxDuration: "RepairMaxDuration",
        SendMaintenanceDoneToPlanner: "SendMaintenanceDoneToPlanner",
        SendRepairDoneToPlanner: "SendRepairDoneToPlanner",
        EnableCostCenterFilter: "EnableCostCenterFilter",
        RepairPlanningUser: "RepairPlanningUser",
        TechnicianTeamSingleScan: "TechnicianTeamSingleScan"
    }
    static readonly SettingNamesDefaults = {
        MaxPoiOffsetMeters: 500,
        SendMaintenancePlanning_Intervals_None: "None",
        SendMaintenancePlanning_Intervals_Weekly: "Weekly",
        SendMaintenancePlanning_Intervals_Monthly: "Monthly",
        SendMaintenancePlanning_Intervals_Both: "Both",
        SendMaintenancePlanning_Intervals_EachAsset: "EachAsset"
    }

    static readonly DefaultBrowserPrintName: "DefaultBrowser";

    static readonly Cookies = {
        ScanningLastLocation: 'ScanningLastLocation'
    }

    static readonly DocumentParameterTypes = {
        Text: 0,
        MultilineText: 1,
        Choices: 2,
        CustomValues: 3
    }

    static readonly MAX_INT = 2147483647;

    static readonly DocumentTypes = {
        InventoryCassation: 'InventoryCassation',
        InventoryDecision: 'InventoryDecision',
        ManagerialStatements: 'ManagerialStatements',
        InventoryStatement: 'InventoryStatement',
        BeneficiaryAssetsStatement: 'BeneficiaryAssetsStatement'
    }

    static readonly AssetHistoryTypes = {
        Basic: 0,
        Bool: 1,
        DateTime: 2
    }

    static readonly ImportCassateSelected = {
        Number: 'number',
        Name: 'name',
        Description: 'description',
    }

    static readonly AssetFilters = {
        Revision: 'Revision',
        MarkedForRemove: 'MarkedForRemove',
        StatusVeryGood: this.PhysicalItemStatuses.VeryGood.toString(),
        StatusGood: this.PhysicalItemStatuses.Good.toString(),
        StatusAcceptable: this.PhysicalItemStatuses.Acceptable.toString(),
        StatusBad: this.PhysicalItemStatuses.Bad.toString(),
        StatusUnspecified: this.PhysicalItemStatuses.Unspecified.toString()
    }

    static readonly AssetFilterTypes = {
        Statuses: 'statuses',
        Revision: 'revision',
        MarkedForRemove: 'MarkedForRemove'
    }

    static readonly GeolocationError = {
        PERMISSION_DENIED: 1,
        POSITION_UNAVAILABLE: 2,
        TIMEOUT: 3,
    }

    static readonly AlertTypes = {
        MaintenanceOverdue: 1,
        WarrantyExpired: 2
    }   
}

export enum ImportColumnTypes {
    String = 'string',
    Int = 'int',
    IntNullable = 'int_nullable',
    Number = 'number',
    NumberNullable = 'number_nullable',
    Date = 'date',
    Choice = 'choice'
}

export enum PrintDeviceTypes {
    Default = 0,
    Zebra = 1
}

export enum SortByDirection {
    Ascending = 0,
    Descending = 1
}
